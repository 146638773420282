import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import http from '../../../http';

import './style.scss';
const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string()
    .required('This field is required')
});
const Contact = () => {
  const [contactData, setContactData] = useState()
  useEffect(async () => {
    const contact = await http.get("/contacts");
    if (contact.data) {
      const [data] = contact.data;
      setContactData(data);
    }
  }, []);

  console.log(contactData);

  function addBreakLines(string, splitString) {
    return string.split(splitString).map((s) => (<span>{s}<br/></span>))
  }

  const {
    contact_heading = '',
    contact_subheading = '',
    contact_CTA = '',
    banner_title = '',
    banner_subtitle = '',
  } = contactData || {};

  return (
    <div className="contact">
      <div className="contact-form">
        <div className="container">
          <div className="row ">
            <div className="col-lg-4">
              <h1>
                {addBreakLines(contact_heading, '\n')}
              </h1>
              <p>{contact_subheading}</p>
            <button>{contact_CTA}</button>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
            <Formik
      initialValues={{
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
      }}
      validationSchema= {SignUpSchema}
      onSubmit={async (values, formik) => {
        console.log(formik)
        const data = values;
        data.username = values.email;
        const payload  = await http.post('/contact-form', data);
        formik.resetForm();
      }}
    >
      {({ errors, touched, dirty, isValid }) => (
      <Form >
      <Field placeholder="Name" name="name" type="text"/>
      {errors.name && touched.name && <div class="input-error">{errors.name}</div>}
      <Field placeholder="Email" name="email" type="text"/>
      {errors.email && touched.email && <div class="input-error">{errors.email}</div>}
      <Field placeholder="Phone Number" name="phoneNumber" type="text"/>
      {errors.phoneNumber && touched.phoneNumber && <div class="input-error">{errors.phoneNumber}</div>}
      <Field placeholder="Message" name="message" type="text"/>
      {errors.message && touched.message && <div class="input-error">{errors.message}</div>}
      <button  disabled={!(isValid && dirty)} type="submit">SEND</button>
      </Form>
      )}
      </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="gray-section">
        <div className="container">
          <h2>{addBreakLines(banner_title, '\n')}</h2>
        <h3>{banner_subtitle}</h3>
        </div>
      </div>
    </div>
  )
};

export default Contact;
