import { useState, useEffect } from 'react';
import Slider from "react-slick";
import ReactMarkdown from 'react-markdown'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import http from "../../../http";

import './style.scss'

const About = () => {
  const slider2Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }
  const positions = {
    0: {
      title: 'CEO & Founder',
      image: 'https://s3.eu-central-1.wasabisys.com/balkan-apartments/ttm/ttm-4bout.png',
    },
    1: {
      title: 'Head Coach',
      image: '/assets/testimonials/3.jpeg',
    },
    2: {
      title: 'Accountability Coach',
      image: '/assets/testimonials/0.jpeg',
    },
    3: {
      title: 'Client Enrollment Specialist',
      image: '/assets/testimonials/1.jpeg',
    },
  }
  const [index, setIndex] = useState(1);
  const beforeChange = (oldIndex, newIndex) => {
    setIndex(newIndex + 1);
  }
  const [aboutData, setAboutData] = useState();
  const slider1Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <FontAwesomeIcon icon={faChevronRight}/>,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft}/>,
    beforeChange,
  }

  useEffect(async () => {
    const home = await http.get("/abouts");
    console.log(home);
    if (home.data) {
      const [data] = home.data;
      setAboutData(data);
    }
  }, []);

  function addBreakLines(string, splitString) {
    return string.split(splitString).map((s) => (<span>{s}<br/></span>))
  }

  const {
    hero_heading = '',
    banner_title = '',
    banner_subtitle = '',
    timeline: {
      timeline_single = [],
    },
    second_banner_title = '',
    questions = [],
    FAQ_title = '',
    team_members = [],
    team_title = '',
    image_section = {}
  } = aboutData || {
    timeline: {},
  };

  return (<div className="about">
    <div className="about-header">
      <div className="about-header-wrapper container">
        <h1>
          {addBreakLines(hero_heading, '\n')}
        </h1>
        <div className="about-header-image">
          <img src="/assets/hudi.png" alt="Hudi - Thrilled Mama Program"/>
        </div>
      </div>
    </div>
    <div className="gray-section">
      <div className="container">
        <h2>{addBreakLines(banner_title, '\n')}</h2>
        <h3>{banner_subtitle}</h3>
      </div>
    </div>
    <div className="years-wrapper">
      <div className="container">
        {timeline_single.map(({ year, title, paragraph}) => (
          <div className="about-item">
            <div className="year-wrapper">
              <span>{year}</span>
              <img src="/assets/bird-about.png" alt="/assets/bird-about.png"/>
            </div>
            <div className="text-wrapper">
              <p className="content">
                <b>{title}<br/></b><ReactMarkdown>{paragraph}</ReactMarkdown></p>
            </div>
          </div>
        ))}
{/*
        <div className="about-item">
          <div className="year-wrapper">
            <span>2009</span>
            <img src="/assets/bird-about.png" alt="/assets/bird-about.png"/>
          </div>
          <div className="text-wrapper">
            <p className="content">
              <b>Little boy #1 was so soon joined by little boy #2</b>
              and motherhood wasn’t looking so thrilling anymore. It was impossible to keep up with two, and the endless cycles of diapers and dishes turned me into a snapping, frazzled Mom. I started feeling depressed and my anxiety escalated terribly. I was grasping at straws, and dreaming of running away.</p>
          </div>
        </div>
        <div className="about-item">
          <div className="year-wrapper">
            <span>2010</span>
            <img src="/assets/bird-about.png" alt="/assets/bird-about.png"/>
          </div>
          <div className="text-wrapper">
            <p className="content">
              <b>Epiphany #1:</b>
              I realised that this is one giant mistake and being miserable was NOT a prerequisite for motherhood. I wanted to enjoy life again and to feel like a person amongst the never-ending tantrums and toy messes. Hard fact: My life was not going to change unless I DID something. So I did. A big something.</p>
          </div>
        </div>
        <div className="about-item">
          <div className="year-wrapper">
            <span>2010</span>
            <img src="/assets/bird-about.png" alt="/assets/bird-about.png"/>
          </div>
          <div className="text-wrapper">
            <p className="content">
              <b>Powerful learning.</b>
              This was when the healing and training started. I started with the CJEA certification where I did inner child work, I then trained in healing the inner child, somatic trauma release work, and a bunch more modalities. That lead me to pursue all kinds of other trainings. Until today I haven’t stopped being coached and learning how to help people heal. From Tony Robbins events, to Kinesiology, to becoming a Laura Doyle Coach and lots more. I soaked in every bit of info I could get my hands on and utilized the practical ideas and tools I was given.</p>
          </div>
        </div>
        <div className="about-item">
          <div className="year-wrapper">
            <span>2011</span>
            <img src="/assets/bird-about.png" alt="/assets/bird-about.png"/>
          </div>
          <div className="text-wrapper">
            <p className="content">
              <b>Success! Motherhood wasn’t an endless struggle anymore.</b>
              I stopped feeling stressed and guilty 24/7. And best of all. I found time for myself and started feeling like a person again.</p>
          </div>
        </div>
        <div className="about-item">
          <div className="year-wrapper">
            <span>2011</span>
            <img src="/assets/bird-about.png" alt="/assets/bird-about.png"/>
          </div>
          <div className="text-wrapper">
            <p className="content">
              <b>Epiphany #2:</b>
              If I struggled so long, it can’t be I’m the only one. Why not help others out and show them the beauty that motherhood can be. First coaching clients taken on.<br/>
              Deep breath.<br/>
              2011<br/>
              Huge Success.<br/>
            </p>
          </div>
        </div>
        <div className="about-item">
          <div className="year-wrapper">
            <span>2011</span>
            <img src="/assets/bird-about.png" alt="/assets/bird-about.png"/>
          </div>
          <div className="text-wrapper">
            <p className="content">
              <b>My clients start seeing huge results.<br/></b>
              But practically, this wasn’t a long term solution. I’m only one person and I can’t possibly divide myself into pieces and help everyone.<br/>
              Now was the time to open up a deep healing interactive coaching practice where I could help a cohort at a time become the Mamas they want to be.<br/>
              Thrilled Mama Mastery was born 🥂
            </p>
          </div>
        </div>
        <div className="about-item">
          <div className="year-wrapper">
            <span>2011</span>
            <img src="/assets/bird-about.png" alt="/assets/bird-about.png"/>
          </div>
          <div className="text-wrapper">
            <p className="content">
              <b>Let’s spread this thing!<br/></b>
              I started training other capable woman with the Thrilled Mama approach. They’re able to coach our clients using our successful method.
            </p>
          </div>
        </div> */}
      </div>
    </div>
    <div className="gray-section">
      <div className="container">
        <h2>{second_banner_title}</h2>
      </div>
    </div>
    <div className="who-section">
      <div className="container">
        <h2>{addBreakLines(FAQ_title, '\n')}</h2>
        <div className="slider-part">
          <div className="slider">
            <Slider {...slider1Settings}>
              {questions.map(({ question, answer }) => (
                <div className="about-slide">
                  <span class="span1"><br/></span>
                  <span class="span2">
                    {question}<br/></span>
                  <span class="span3">{addBreakLines(answer, '\n')}<br/><br/>
                  </span>
                </div>
              ))}

              {/* <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  2. People usually describe me:<br/></span>
                <span class="span3">
                  As chilled
                  <br/>and fun!
                  <br/>
                  <br/>
                </span>
              </div>
              <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  3. What number on the enneagram?<br/></span>
                <span class="span3">
                  7 (Look it up!)
                  <br/>
                  <br/>
                </span>
              </div>
              <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  4. I HATE:<br/></span>
                <span class="span3">
                  Being bored.
                  <br/>If I’m not constantly growing, I’m in a bad mood.
                  <br/>
                  <br/>
                </span>
              </div>
              <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  5. If I could, I would:<br/></span>
                <span class="span3">
                  ...change the system to a healthier way of living. Not only do you have to be emotionally and mentally healthy,
                  <br/>but physical health is super important.
                  <br/>#sourdoughandyogafan
                  <br/>
                  <br/>
                </span>
              </div>
              <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  6. Fave colour?<br/></span>
                <span class="span3">
                  It’s going to have to be a mix of yellow, orange and pink.
                  <br/>Sunset colors for me!
                  <br/>
                  <br/>
                </span>
              </div>
              <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  7. One day:<br/></span>
                <span class="span3">
                  I want to learn how to ride a horse and for that matter, own a ranch.
                  <br/>(Hey--a girl can dream!)
                  <br/>
                  <br/>
                </span>
              </div>
              <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  8. Fave sport?<br/></span>
                <span class="span3">
                  Basketball,
                  <br/>(but many different kinda sports speak to me!)
                  <br/>
                  <br/>
                </span>
              </div>
              <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  9. It’s important to me:<br/></span>
                <span class="span3">
                  To go on dates with my kids to fun places like rock climbing, go karting, or anywhere fun.
                  <br/>One-on-one time with each is mindblowingly important.
                  <br/>
                  <br/>
                </span>
              </div>
              <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  10. People are always interested in hearing:<br/></span>
                <span class="span3">
                  About my
                  <br/>homebirth experience.
                  <br/>
                  <br/>
                </span>
              </div>
              <div className="about-slide">
                <span class="span1"><br/></span>
                <span class="span2">
                  And most importantly:<br/></span>
                <span class="span3">
                  11. What they’re saying
                  <br/>about me and my program?
                  <br/>
                  <br/>
                </span>
              </div> */}
            </Slider>
            <div className="counter">
              <span class="span1 counter">{index}/{questions.length}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="slider-section citation-section">
      <div class="container slider-wrapper">
        <div className="text-section">
          <h2>“You simply cannot compare the before and after”</h2>

          <Slider {...slider2Settings}>
            <div className="route-slide">
              <p>
                Doing the course helped me face my challenges
                and deal with them correctly, and because of that
                things settled down.<br/>
                Finally, life is flowing and generally stable. I feel
                happy and I'm doing the stuff I enjoy most and
                taking a course on something I'm really passionate
                about. Is it perfect? No. I still struggle with specific
                challenges, but you simply cannot compare the
                before and after.
              </p>
              <span>B. Lebovits</span>
            </div>
            <div className="route-slide">
              <h2>&nbsp;</h2>
              <p>
                I can have so much going on, but even on those
                days, I greet it with exuberant amounts of energy
                and the feeling of ‘I got you’! It comes from the self
                healing and the self worth work I’ve done.
              </p>
              <span>Chanee Lefkowitz</span>
            </div>
            <div className="route-slide">
              <h2>&nbsp;</h2>
              <p>
                My personal goal was to become more joyful,
                not just survive. To work on figuring out how to
                do self-care. I learn that the more you focus on
                yourself the more you can give to others.
              </p>
              <span>Hadassa Eventsur</span>
            </div>
          </Slider>
        </div>
        <div className="slider-part"></div>
      </div>
    </div>
    <div className="team-section">
      <div className="container">
        <h2>{team_title}</h2>
        <div className="row justify-center">
          {team_members.map(({ name='', description='', position='' }, index) => (
            <div className="col-lg-3">
              <div className="team-item">
                <img src={positions[index].image} alt="circle" />
                <h3 className="team-name">
                  {name}
                  <br></br>
                  <i>{positions[index].title}</i>
                </h3>
                <p>{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="slider-section citation-section">
      <div class="container slider-wrapper">
        <div className="text-section">
          <h2>{image_section.title || 'Are we a good fit?'}</h2>
          <p>
            {<ReactMarkdown>{image_section.paragraph}</ReactMarkdown>}
          </p>
        </div>
        <div className="slider-part" style={{backgroundImage: `url(${image_section?.image?.url || 'https://s3.eu-central-1.wasabisys.com/tarik/baby-2.png'})`}}></div>
      </div>
    </div>
  </div>)
}

export default About;
