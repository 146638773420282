import { Field, Form, Formik } from 'formik';
import http from '../../../http';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import './style.scss'

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .required('This field is required')
    .min(4, 'Too Short!'),
  email: Yup.string().email('Invalid email').required('This field is required'),
});
const Login = () => {
  const history = useHistory();

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>Login</h1>
        <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        terms: false,
      }}
      validationSchema= {LoginSchema}
      onSubmit={async (values) => {
        const data = {
          identifier: values.email,
          password: values.password
        };
        const payload  = await http.post('/auth/local', data);
        if(payload){
          const userProfile = await http.get(`/users/${payload.data.user.id}`, { headers: { Authorization: `Bearer ${payload.data.jwt}`}});
          console.log(payload)
          console.log(payload.jwt)
          console.log(userProfile);
          localStorage.setItem('currentUser', JSON.stringify(payload.data.user));
          localStorage.setItem('token', payload.data.jwt);
          localStorage.setItem('userId', payload.data.user.id);
          localStorage.setItem('userProfile', JSON.stringify(userProfile.data));
          history.replace("/")
        }
      }}
    >
            {({ errors, touched, dirty, isValid }) => (
      <Form >
       <Field placeholder="Email" name="email" type="text"/>
      {errors.email && touched.email && <div class="input-error">{errors.email}</div>}
      <Field placeholder="Password" name="password" type="password"/>
      {errors.password && touched.password && <div class="input-error">{errors.password}</div>}
        <div className="login-actions-wrapper">
          <label>
            <input type="checkbox" checked="checked" style={{ 'marginRight': '5px' }} />
            Remember me
            <span class="checkmark"></span>
          </label>
          <a href="/forgot-password">Forgot password?</a>
        </div>
        <button disabled={!(isValid && dirty)}  type="submit">LOG IN</button>
        <div className="register-write">
          Not a member?
          <a href="/register">Register to create an account.</a>
        </div>
        </Form>
   )}
         </Formik>
      </div>
    </div>
  )
}

export default Login;
