import {useEffect, useRef, Fragment, useState} from "react";
import Slider from "react-slick";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import http from "../../../http";
import {hData} from "./constants";
import "./style.scss";

const Home = () => {
  const slider1Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <FontAwesomeIcon icon={faChevronRight}/>,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft}/>
  };
  const slider2Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const [homeData, setHomeData] = useState(hData);

  const {
    home_hero: {
      home_CTA = hData.home_CTA,
      home_subtitle = hData.home_subtitle,
      home_title = hData.home_title,
    },
    Rewards: rewards = hData.Rewards,
    slider_section = hData.slider_section,
    lighthouse_section = hData.lighthouse_section,
    motherhood_section = hData.motherhood_section,
    slider_section_2 = hData.slider_section_2,
    hudi_section = hData.hudi_section,
    directions_section: {
      direction,
      title: directionsTitle
    } = hData.directions_section,
    image_section = hData.image_section,
    testimonails_section = hData.testimonails_section,
    summary_section = hData.summary_section,
  } = homeData;

  useEffect(async () => {
    const home = await http.get("/homes");
    if (home.data) {
      const [data] = home.data;
      setHomeData(data);
    }
  }, []);

  const [mastery] = direction;
  const otherDirections = [...direction].slice(1);
  const history = useHistory();

  const url = [
    '/services/#oneonone',
    '/subscribe',
  ]

  return (<Fragment>
    <div className="home">
      <div className="hero-wrapper">
        <div className="hero-overlay">
        </div>
        <div className="container">
          <h1>
            {home_subtitle}
          </h1>
          <h3>
            {home_title}
          </h3>
          <button onClick={() => { history.push('/services')}}>{home_CTA}</button>
        </div>
      </div>
      <div className="banner-wrapper">
        <div className="container logo-wrapper">
          {
            rewards.map(({Logotype}) => {
              const [image] = Logotype;
              return (<img src={`${image.url}`} alt={image.name}/>)
            })
          }
        </div>
      </div>
      <div className="lighthouse-section">
        <div className="container">
          <h2>{lighthouse_section.header_title.split('?')[0]}?</h2>
          <div className="lighthouse-wrapper">
            <div className="lh-text-section">
              <h4>
                {lighthouse_section.header_title.split('?')[1]}
              </h4>
              <p>
                <img src="/assets/bird.png" alt="bird"/> {lighthouse_section.normal_text_1}
              </p>
              <h4>{lighthouse_section.heading_text_1}</h4>
              <p>
                <img src="/assets/bird.png" alt="bird"/> {lighthouse_section.normal_text_2}
              </p>
              <h4>{lighthouse_section.heading_text_2}</h4>
              <p>
                <img src="/assets/bird.png" alt="bird"/> {lighthouse_section.normal_text_3}
              </p>
              <h4>{lighthouse_section.heading_text_3}</h4>
              <p>
                <img src="/assets/bird.png" alt="bird"/> {lighthouse_section.normal_text_4}
              </p>
            </div>
            <img class="lighthouse-image" src="/assets/lighthouse.png" alt="lighthouse"/>
          </div>
        </div>
      </div>
      <div className="guess-what-section">
        <div className="container">
          <h2>{motherhood_section.header_title}</h2>
          <h4>{motherhood_section.header_subtitle}</h4>
          <img src="/assets/hearts.png" alt="hearts"/>
          <h2>{motherhood_section.title}</h2>
          <h4 class="orange">{motherhood_section.subtitle}</h4>
          <p><ReactMarkdown>{motherhood_section.paragraph}</ReactMarkdown></p>
        </div>
      </div>
      <div className="slider-section">
        <div class="container slider-wrapper">
          <div className="text-section">
            <h2>{slider_section_2.slider_title}</h2>
            <p>{slider_section_2.slider_subtitle}</p>
            <button onClick={() => { history.push('/about')}}>{slider_section_2.slider_CTA}</button>
          </div>
          <div className="slider-part">
            <div className="slider">
              <Slider {...slider1Settings}>
                {
                  slider_section_2.slider_items.map(({slider_text}) => (<div className="home-slide">
                    <img src="/assets/bird-slider.png" alt="bird-slider.png"/>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {slider_text}
                    </ReactMarkdown>
                  </div>))
                }
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="hi-section">
        <div className="container">
          <div className="hi-header-section">
            <img src="/assets/hi-hudi.png" alt="Hi!"></img>
            <div className="hi-header-section-text">
              <h2>{hudi_section.Title}</h2>
              <h3>{hudi_section.subtitle.split('.').map((s) => (<span>{s}<br/></span>))}</h3>
            </div>
          </div>
          <p>
            <ReactMarkdown>{hudi_section.description}</ReactMarkdown>
          </p>
          <div className="hi-button">
            <h3 className="hi-bottom-h3">{hudi_section.label}</h3>
            <button onClick={() => { history.push('/services')}}>{hudi_section.CTA}</button>
          </div>
        </div>
      </div>
      <div className="direction-section">
        <div className="container">
          <h2>{directionsTitle}</h2>
          <div className="row">
            <div className="col-lg-4">
              <div className="direction-item">
                <img src="/assets/bird-2.png" className="bird" alt="bird-slider.png"/>
                <h3>{mastery.title}</h3>
                <p>
                  {mastery.subtitle}
                </p>
                <ul>
                  {
                    mastery.description.split(',').map((s) => (<li>
                      <img src="/assets/check.png" alt="check.png"/>
                      <span>{s}</span>
                    </li>))
                  }
                </ul>
                <button onClick={() => { window.location.href = '/services/#mastery' }}>{mastery.CTA}</button>
              </div>
            </div>
            {
              otherDirections.map((dir, index) => (<div className="col-lg-4">
                <div className="direction-item">
                  <img src="/assets/bird-2.png" className="bird" alt="bird-slider.png"/>
                  <h3>{dir.title}</h3>
                  <p>{dir.subtitle}</p>
                  <p>{dir.description}</p>
                  <button onClick={() => { window.location.href = url[index] }}>{dir.CTA}</button>
                </div>
              </div>))
            }
          </div>
        </div>
      </div>
      <div className="slider-section citation-section">
        <div class="container slider-wrapper">
          <div className="text-section">
            <h2>{image_section.title}</h2>
            <p>
              {image_section.paragraph}
              <br/>
              <br/>
              <b>{image_section.author}</b>
            </p>
          </div>
          <div className="slider-part"></div>
        </div>
      </div>
      <div className="route-section">
        <div className="container">
          <h2>
            {testimonails_section.dynamic_title.map(({ title }) => {
              return (<>{title} <br/></>)
            })}
          </h2>
          <div className="route-slider">
            <Slider {...slider2Settings}>
              {
                testimonails_section.testimonial_slider.map(({testimonial, testimonial_author, subtitle, paragraph = ''}) => (
                  <>
                    <div className="route-header">
                      <h3>{subtitle}</h3>
                      <p>{paragraph.split('\n').map((s) => (<span>{s}<br/></span>))}</p>
                    </div>
                    <div className="route-slide">
                      <p>
                        {testimonial}
                      </p>
                      <span>{testimonial_author}</span>
                    </div>
                  </>
                ))
              }
            </Slider>
          </div>
        </div>
      </div>
      <div className="thing-section">
        <div className="container">
          <h2>{summary_section.title}</h2>
          <h3>
            {summary_section.paragraph.split('\n').map((s) => (<span>{s}<br/></span>))}
          </h3>
          <h2 className="or-h2">
            {summary_section.bold_paragraph.split('\n').map((s) => (<span>{s}<br/></span>))}
          </h2>
          <button onClick={() => { history.push('/services') }}>Show me how</button>
        </div>
      </div>
    </div>
  </Fragment>);
};

export default Home;


// <div className="slider-section">
//   <div class="container slider-wrapper">
//     <div className="text-section">
//       <h2>{slider_section.slider_title}</h2>
//       <p>{slider_section.slider_subtitle}</p>
//       <button>{slider_section.slider_CTA}</button>
//     </div>
//     <div className="slider-part">
//       <div className="slider">
//         <Slider {...slider1Settings}>
//           {
//             slider_section.slider_items.map(({slider_text}) => (<div className="home-slide">
//               <img src="/assets/bird-slider.png" alt="bird-slider.png"/>
//               <p>
//                 {slider_text}
//               </p>
//             </div>))
//           }
//         </Slider>
//       </div>
//     </div>
//   </div>
// </div>
