import { Field, Form, Formik } from 'formik';
import {useState} from 'react';
import http from '../../../http';
import * as Yup from 'yup';
import {  useHistory, useLocation } from "react-router-dom";
import './style.scss'

const SignUpSchema = Yup.object().shape({
    password: Yup.string()
      .required('This field is required')
      .min(4, 'Too Short!'),
    confirmPassword: Yup.string().required('This field is required').when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both passwords must match'
      ),
    }),
  });
const PasswordReset = () => {
    const history = useHistory();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const code = params.get('code');
    debugger
    const [sent, setSent] = useState(false);
  return (<div className="login-container">
    <div className="login-form">
      <h1>Reset password</h1>
      <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        terms: false,
      }}
      validationSchema= {SignUpSchema}
      onSubmit={async (values) => {
        const data = values;
        const payload  = await http.post(`${process.env.REACT_APP_BE_URL}/auth/reset-password`, {
            code: code,
            password: values.password,
            passwordConfirmation: values.confirmPassword
          })
          .then(response => {
            // Handle success.
            history.push("/confirmation?type=reset")
          })
          .catch(error => {
            // Handle error.
            console.log('An error occurred:', error.response);
          });;
      }}
    >
      {({ errors, touched, dirty, isValid }) => (
      <Form >
      <Field placeholder="Password" name="password" type="password"/>
      {errors.password && touched.password && <div class="input-error">{errors.password}</div>}
      <Field placeholder="Repeat Password" name="confirmPassword" type="password"/>
      {errors.confirmPassword && touched.confirmPassword && <div class="input-error">{errors.confirmPassword}</div>}
      <button  disabled={!(isValid && dirty)} type="submit">RESET PASSWORD</button>
      </Form>
      )}
      </Formik>
    </div>
  </div>)
}

export default PasswordReset;
