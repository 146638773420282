import './style.scss';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import http from '../../../http';

const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string()
    .required('This field is required')
});
const Footer = () => {
  
  return (
    <footer>
      <div className="container">
        <div className="footer-wrapper">
        <Formik
      initialValues={{
        name: '',
        email: '',
        message: '',
      }}
      validationSchema= {SignUpSchema}
      onSubmit={async (values, formik) => {
        console.log(formik)
        const data = values;
        data.username = values.email;
        const payload  = await http.post('/contact-form', data);
        formik.resetForm();
      }}
    >
      {({ errors, touched, dirty, isValid }) => (
      <Form >
      <Field placeholder="Name" name="name" type="text"/>
      {errors.name && touched.name && <div class="input-error">{errors.name}</div>}
      <Field placeholder="Email" name="email" type="text"/>
      {errors.email && touched.email && <div class="input-error">{errors.email}</div>}
      <Field placeholder="Message" name="message" type="text"/>
      {errors.message && touched.message && <div class="input-error">{errors.message}</div>}
      <button  disabled={!(isValid && dirty)} type="submit">SEND</button>
      </Form>
      )}
      </Formik>
          <img src="/assets/contact-bg.png" alt="map" />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
