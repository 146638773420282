import React, { useEffect, useRef } from 'react';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

import "./style.scss";

const Services = () => {
  const slider2Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  const history = useHistory();
  const masteryRef = useRef(null);
  const oneononeRef = useRef(null);
  const executeScroll = ({ ref, element }) => {
    if (ref) {
      element.current.scrollIntoView()
    } else {
      element.scrollIntoView()
    }
  };

  useEffect(() => {
    let currentLocation = window.location.href;
    const hasElementAnchor = currentLocation.includes("/#");
    if (hasElementAnchor) {
      const anchorElementId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
      const anchorElement = document.getElementById(anchorElementId);
      executeScroll({ ref: false, element: anchorElement});
    }
  }, []);

  return (<div className="services">
    <div className="services-hero">
      <div className="container">
        <h2>
          Nobody ever told you that motherhood would be so overwhelmingly difficult.
          <br/>
          And nobody told you that you never. ever. get a break.
        </h2>
        <h1>
          In short: This isn’t what you
          <br/>
          signed up for.
        </h1>
      </div>
    </div>
    <div className="tried-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>You’ve tried it all:</h2>
            <p className="left-text">
              <img src="/assets/bird-2.png" alt=""/>
              Parenting classes? Check
            </p>
            <p className="left-text">
              <img src="/assets/bird-2.png" alt=""/>
              Self-help books? Absolutely
            </p>
            <p className="left-text">
              <img src="/assets/bird-2.png" alt=""/>
              Too much caffeine? Oh yeah
            </p>
            <h3>...And maybe you’ve even tried therapy.</h3>
          </div>
          <div className="col-lg-12">
            <h2>But it just doesn’t cut it.</h2>
            <p>
              Those days where you pictured yourself cutting sandwiches into flowery shapes and singing night time lullabies has all but disappeared into mountains of laundry and colossal to-do lists.
              <br/>
              <br/>
              But forget being supermom. All you want is to:
              <br/>
              -enjoy your kids (And not just when they’re sleeping.)
              <br/>
              -quit losing it every.single.day (And stop feeling like a monster.)
              <br/>
              -end the day without feeling so emotionally drained (And physically drained. And mentally drained.)
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="solution-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>
              Being the best Mom boils down to finding the happiest, most passionately alive version of yourself. And the team at Thrilled Mama will give you:
            </h2>
            <h3>
              <img src="/assets/check-orange.png" alt="X"/>
              the skills, tools, and techniques to show you how to find that nonstop support along the way
            </h3>
            <h3>
              <img src="/assets/check-orange.png" alt="X"/>
              the strengths you need, so that being
            </h3>
            <h3>
              <img src="/assets/check-orange.png" alt="X"/>
              the best Mom comes naturally to you
            </h3>
          </div>
          <div className="col-lg-12">
            <h2>The solution? It’s not :</h2>
            <h3>
              <img src="/assets/cross.png" alt="X"/>
              how to fix your kids
            </h3>
            <h3>
              <img src="/assets/cross.png" alt="X"/>
              how to fix your husband
            </h3>
            <h3>
              <img src="/assets/cross.png" alt="X"/>
              how many star charts you
              <br/>
              can create
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div className="gray-section">
      <div className="container">
        <h2>
          “I don’t feel like the family rag anymore.
          <br/>
          I have my life back and my confidence
          <br/>
          has soared.”
        </h2>
        <h3>Miriam B</h3>
      </div>
    </div>
    <div className="service-section">
      <div className="container">
        <h2>
          No, it’s not magic.
          <br/>A taste of what modalities we use:
        </h2>
        <p class="p-0">
          Powerful healing work. Practical exercises. Mind-transforming exercises. Though it’s not about learning, it’s about becoming.
          <br/>
          <b>You learn to shift who you are</b>
          <br/>
          by understanding each and every part of yourself so that you feel a whole lot more vibrant.
        </p>
        <p>
          <b>You’ll learn to understand how the mind works,</b>
          <br/>
          how you work, and how to give yourself what you need to become who you want to be.
        </p>
        <p>
          <b>I will not promise you a happily-ever-after ending</b>
          <br/>
          with zero future challenges (please don’t trust anyone who does). Even emotionally sound people are not exempt from kids who tantrum or from normal life challenges.
        </p>
        <h3>
          You gain the strengths and resilience to deal with whatever roadblocks
          <br/>
          and challenges that come your way.
        </h3>
      </div>
    </div>
    <div className="direction-section">
      <div className="container">
        <h2>What’s right for you?</h2>
        <div className="row">
          <div className="col-lg-4">
            <div className="direction-item">
              <img src="/assets/bird-2.png" className="bird" alt="bird-slider.png"/>
              <h3>Mastery program</h3>
              <p>
                A 6 month in-depth program giving you the tools and coaching you need to be the best version of yourself + the happiest Mom you could be
                <br/>
                <br/>
              </p>
              <button onClick={() => executeScroll({ ref: true, element: masteryRef })}>Learn more</button>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="direction-item">
              <img src="/assets/bird-2.png" className="bird" alt="bird-slider.png"/>
              <h3>One-on-one coaching</h3>
              <p>
                1 on 1 personalized coaching program with a huge amount of personal support, with a certified Thrilled Mama coach.
                <br/>
                <br/>
                <br/>
              </p>
              <button onClick={() => executeScroll({ ref: true, element: oneononeRef })}>Learn more</button>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="direction-item">
              <img src="/assets/bird-2.png" className="bird" alt="bird-slider.png"/>
              <h3>Video library</h3>
              <p>
                Opt for a monthly or yearly subscription and get access to a full library of educational and practical videos. You also have the option of buying single one-off videos without subscribing.
              </p>
              <button onClick={() => { history.push('/subscribe')}}>Learn more</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="banner-wrapper">
      <div className="container logo-wrapper">
        <img src="/assets/logo/1.png" alt="logo"/>
        <img src="/assets/logo/2.png" alt="logo"/>
        <img src="/assets/logo/3.png" alt="logo"/>
        <img src="/assets/logo/4.png" alt="logo"/>
        <img src="/assets/logo/5.png" alt="logo"/>
        <img src="/assets/logo/6.png" alt="logo"/>
        <img src="/assets/logo/7.png" alt="logo"/>
      </div>
    </div>
    <div ref={masteryRef} id="mastery" className="service-section">
      <div className="container">
        <h2>
          Thrilled Mama Mastery
          <br/>
          (6 month program)
        </h2>
        <p>
          <b>WHAT YOU GET:</b>
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          Access to the Thrilled Mama membership site. So you get step-by-step content that walks you through the structure of the program.
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          Live group zoom calls with Thrilled Mama coaches. Where you get to celebrate wins and receive super-encouraging accountability.
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          Group Q and A’s with me. So you can ask your deeper questions, taking everything you learned to a whole new level.
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          Exclusive access to a private zero-judgement community. So you get authentic connection + support, and fun camaraderie from women dealing with the same things and growing on the same journey.
          <br/>
        </p>
        <p>
          <b>THIS PROGRAM IS FOR YOU IF:</b>
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          You have over-devoted Mama syndrome because you run yourself ragged forgetting that you’re a person too.
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          You want support and encouragement to become a happier -- more healthy human.
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          You want to give your family the best version of yourself.
          <br/>
        </p>
        <p>
          <b>HOW TO JOIN IN 3 EASY STEPS:</b>
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          Click “Apply now”
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          Fill out the questionnaire
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          You’ll be invited to an intake call to establish if this is the right fit for you
          <br/>
        </p>
        <h3>
          Ready?
          <button onClick={() => { history.push('/contact')}}>Apply now</button>
          Still have questions?
          <button onClick={() => { history.push('/contact')}}>Let's talk</button>
        </h3>
      </div>
    </div>
    <div className="slider-section citation-section">
      <div class="container slider-wrapper">
        <div className="text-section">
          <h2>What are they saying?</h2>
          <Slider {...slider2Settings}>
            <div className="route-slide">
              <h2>“The whole house feels calmer and happier”</h2>
              <p>
                My husband has a tendency not to like programs as much as I do. But this one is different. He says he feels real change in me and that miraculously the whole house feels calmer and happier.
              </p>
              <span>Ahuva B</span>
            </div>
            <div className="route-slide">
              <h2>“It gave me strength and courage”</h2>
              <p>
                When I started the program I was desperate for any sort of discount because finances were a sore point. Guess what? Doing the program gave me the strength and the courage to finally get my real estate license, today I closed on my first home sale. Credit is all due to you.
              </p>
              <span>Elisheva R</span>
            </div>
            <div className="route-slide">
              <h2>“They’re still supporting me”</h2>
              <p>
                The feeling of being part of something was like nothing else. I always knew that there would be someone there for me when I needed it most. I created friendships outside of the program walls and they are still supporting me
              </p>
              <span>Ariella B</span>
            </div>
          </Slider>
        </div>
        <div className="slider-part"></div>
      </div>
    </div>
    <div ref={oneononeRef} id="oneonone" className="service-section">
      <div className="container">
        <h2>
          One-on-one
          <br/>
          coaching
        </h2>
        <p>
          <b>WHAT YOU GET:</b>
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          1 on 1 private zoom calls with a Thrilled Mama coach
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>Continuous hand-holding and support
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          Exercise, videos, and healing work to do in between calls
          <br/>
        </p>
        <p>
          <b>THIS PROGRAM IS FOR YOU IF:</b>
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          You have specific issues you’d like to work though
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          You’re ready and willing to invest BIG in yourself
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          You’re committed to self-growth even without a group cheering you on
          <br/>
        </p>
        <p>
          <b>HOW TO JOIN IN 3 EASY STEPS:</b>
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          Click “Apply now”
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          Fill out the questionnaire
          <br/>
          <img src="/assets/check.png" alt="check.png" style={{
              marginRight: "5px"
            }}/>
          You’ll be invited to an intake call to establish if this is the right fit for you
          <br/>
        </p>
        <h3>
          Ready?
          <button onClick={() => { history.push('/contact')}}>Apply now</button>
          Still have questions?
          <button onClick={() => { history.push('/contact')}}>Let's talk</button>
        </h3>
      </div>
    </div>
    {/*<div className="saying-section">
      <div className="container">
        <h2>What they’re saying</h2>
        <div className="row">
          <div className="col-lg-4">
            <img src="/assets/block-saying.png" alt="block-saying" />
          </div>
          <div className="col-lg-4">
            <img src="/assets/block-saying.png" alt="block-saying" />
          </div>
          <div className="col-lg-4">
            <img src="/assets/block-saying.png" alt="block-saying" />
          </div>
        </div>
      </div>
    </div>*/
    }
    <div className="faq-section">
      <div className="container">
        <div className="col-lg-10">
          <h2>All your questions -- answered</h2>
          <div className="question-item">
            <h3>
              Q: I’m busy. I’m holding down a job and my kids are a 24/7 job in itself. Is this going to give me hours of additional work?
            </h3>
            <p>
              A: I get you. This course was MADE for busy moms. But more than that. We’ve had clients who do this program one bite-size chunk at a time and see enormous change just from putting in little bits as they go along.
            </p>
          </div>
          <div className="question-item">
            <h3>Q: How many hours a week on average do I need to give?</h3>
            <p>
              A: Interesting tidbit of the day: The type of work we teach gives you more time. No, we’re not claiming to be miracle workers. It’s counter- intuitive but it’s content that clears your mind, relaxes you and suddenly you have so much more time to get stuff done.
            </p>
          </div>
          <div className="question-item">
            <h3>
              Q: I’m a pretty private person. Is there an option besides the Mastery?
            </h3>
            <p>
              A: Yes! You can apply for one on one coaching. BUT: One of the reasons you may feel the need for privacy is because you’ve never experienced being in a real, honest and vulnerable setting with support and zero-judgement. The group program serves you on a deeper level that one-on-one can’t do.
            </p>
          </div>
          <div className="question-item">
            <h3>Q: How is it different from therapy or coaching?</h3>
            <p className="no-m-p">
              A: Let’s detail it for you: Therapy: Once a week. You focus on past pain and work on that microscopic area. Slow growth. Coaching: Once a week guidance on how to move forward. No support in between sessions and no focus on deep healing Thrilled Mama:
            </p>
            <div className="check-wrapper">
              <span>
                <img src="/assets/check-orange.png" alt="check-orange"/>
                Ongoing support
              </span>
              <span>
                <img src="/assets/check-orange.png" alt="check-orange"/>
                Practical exercises
              </span>
              <span>
                <img src="/assets/check-orange.png" alt="check-orange"/>
                New self awareness
              </span>
              <span>
                <img src="/assets/check-orange.png" alt="check-orange"/>
                Focus on healing and moving forward
              </span>
            </div>
          </div>
          <div className="question-item">
            <h3>Q: How is it different from parenting classes?</h3>
            <p>
              A: Parenting courses teach you how to deal with your children. They also don’t offer personalized hand-holding and coaching. Thrilled Mamas focuses on YOU and how to deeply understand the strengths of yourself. With inner awareness and understanding you can parent your children so. much. more effectively.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>);
};

export default Services;
