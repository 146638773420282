export const hData = {
  "id": 1,
  "published_at": "2021-11-23T22:10:10.365Z",
  "created_at": "2021-11-23T21:46:53.327Z",
  "updated_at": "2021-12-06T21:49:38.724Z",
  "Rewards": [
    {
      "id": 1,
      "Logotype": [
        {
          "id": 38,
          "name": "1.png",
          "alternativeText": "",
          "caption": "",
          "width": 131,
          "height": 91,
          "formats": null,
          "hash": "1_f47d3bfd37",
          "ext": ".png",
          "mime": "image/png",
          "size": 9.79,
          "url": "https://res.cloudinary.com/t-brainstorm/image/upload/v1638827336/1_f47d3bfd37.png",
          "previewUrl": null,
          "provider": "cloudinary",
          "provider_metadata": {
            "public_id": "1_f47d3bfd37",
            "resource_type": "image"
          },
          "created_at": "2021-12-06T21:48:56.426Z",
          "updated_at": "2021-12-06T21:48:56.510Z"
        }
      ]
    },
    {
      "id": 2,
      "Logotype": [
        {
          "id": 39,
          "name": "2.png",
          "alternativeText": "",
          "caption": "",
          "width": 198,
          "height": 58,
          "formats": null,
          "hash": "2_294afe0b1d",
          "ext": ".png",
          "mime": "image/png",
          "size": 8.04,
          "url": "https://res.cloudinary.com/t-brainstorm/image/upload/v1638827336/2_294afe0b1d.png",
          "previewUrl": null,
          "provider": "cloudinary",
          "provider_metadata": {
            "public_id": "2_294afe0b1d",
            "resource_type": "image"
          },
          "created_at": "2021-12-06T21:48:56.470Z",
          "updated_at": "2021-12-06T21:48:56.511Z"
        }
      ]
    },
    {
      "id": 3,
      "Logotype": [
        {
          "id": 43,
          "name": "3.png",
          "alternativeText": "",
          "caption": "",
          "width": 182,
          "height": 63,
          "formats": null,
          "hash": "3_91457e628c",
          "ext": ".png",
          "mime": "image/png",
          "size": 6.94,
          "url": "https://res.cloudinary.com/t-brainstorm/image/upload/v1638827336/3_91457e628c.png",
          "previewUrl": null,
          "provider": "cloudinary",
          "provider_metadata": {
            "public_id": "3_91457e628c",
            "resource_type": "image"
          },
          "created_at": "2021-12-06T21:48:56.836Z",
          "updated_at": "2021-12-06T21:48:56.863Z"
        }
      ]
    },
    {
      "id": 4,
      "Logotype": [
        {
          "id": 41,
          "name": "4.png",
          "alternativeText": "",
          "caption": "",
          "width": 98,
          "height": 83,
          "formats": null,
          "hash": "4_a37a1cdaf2",
          "ext": ".png",
          "mime": "image/png",
          "size": 6.68,
          "url": "https://res.cloudinary.com/t-brainstorm/image/upload/v1638827336/4_a37a1cdaf2.png",
          "previewUrl": null,
          "provider": "cloudinary",
          "provider_metadata": {
            "public_id": "4_a37a1cdaf2",
            "resource_type": "image"
          },
          "created_at": "2021-12-06T21:48:56.820Z",
          "updated_at": "2021-12-06T21:48:56.858Z"
        }
      ]
    },
    {
      "id": 5,
      "Logotype": [
        {
          "id": 42,
          "name": "5.png",
          "alternativeText": "",
          "caption": "",
          "width": 72,
          "height": 106,
          "formats": null,
          "hash": "5_9844ef5f79",
          "ext": ".png",
          "mime": "image/png",
          "size": 6.67,
          "url": "https://res.cloudinary.com/t-brainstorm/image/upload/v1638827336/5_9844ef5f79.png",
          "previewUrl": null,
          "provider": "cloudinary",
          "provider_metadata": {
            "public_id": "5_9844ef5f79",
            "resource_type": "image"
          },
          "created_at": "2021-12-06T21:48:56.824Z",
          "updated_at": "2021-12-06T21:48:56.867Z"
        }
      ]
    },
    {
      "id": 6,
      "Logotype": [
        {
          "id": 40,
          "name": "6.png",
          "alternativeText": "",
          "caption": "",
          "width": 155,
          "height": 56,
          "formats": null,
          "hash": "6_6fc0232a09",
          "ext": ".png",
          "mime": "image/png",
          "size": 4.93,
          "url": "https://res.cloudinary.com/t-brainstorm/image/upload/v1638827336/6_6fc0232a09.png",
          "previewUrl": null,
          "provider": "cloudinary",
          "provider_metadata": {
            "public_id": "6_6fc0232a09",
            "resource_type": "image"
          },
          "created_at": "2021-12-06T21:48:56.804Z",
          "updated_at": "2021-12-06T21:48:56.855Z"
        }
      ]
    },
    {
      "id": 7,
      "Logotype": [
        {
          "id": 44,
          "name": "7.png",
          "alternativeText": "",
          "caption": "",
          "width": 80,
          "height": 80,
          "formats": null,
          "hash": "7_c6ea6ddb60",
          "ext": ".png",
          "mime": "image/png",
          "size": 5.04,
          "url": "https://res.cloudinary.com/t-brainstorm/image/upload/v1638827336/7_c6ea6ddb60.png",
          "previewUrl": null,
          "provider": "cloudinary",
          "provider_metadata": {
            "public_id": "7_c6ea6ddb60",
            "resource_type": "image"
          },
          "created_at": "2021-12-06T21:48:57.147Z",
          "updated_at": "2021-12-06T21:48:57.163Z"
        }
      ]
    }
  ],
  "home_hero": {
    "id": 1,
    "home_title": "“Sometimes I wonder if I just wasn’t cut out to be a mother\"",
    "home_subtitle": "If you've ever thought this, you're in great company -- (and yes, you're normal)!",
    "home_CTA": "YES! TELL ME HOW."
  },
  "slider_section": {
    "id": 1,
    "slider_title": "But what if you could actually *enjoy* being a mother ...",
    "slider_subtitle": "and also have time for yourself?",
    "slider_CTA": "TELL ME HOW",
    "slider_items": [
      {
        "id": 1,
        "slider_text": "Wake up with a smile on your face instead of moaning through the morning"
      }
    ],
    "slider_image": null
  },
  "lighthouse_section": {
    "id": 1,
    "header_title": "Does this sound familiar? I love my kids",
    "normal_text_1": "with children who fight nonstop,",
    "normal_text_2": "with a demanding exhausting job that doesn’t stick to the 9-5 parents",
    "normal_text_3": "with that principal who wants my kid out pronto,",
    "normal_text_4": "when coping with a preteen fashionista who thinks she always knows best,",
    "heading_text_1": "when dealing with (not one but TWO) kids with ADHD,",
    "heading_text_2": "with the never-ending monotony of changing diapers-doling out che",
    "heading_text_3": "with an endlessly colicky newborn,",
    "heading_text_4": "when coping with a preteen fashionista who thinks she always knows best,"
  },
  "motherhood_section": {
    "id": 1,
    "header_title": "It’s Just. Too. Much.",
    "header_subtitle": "(Self care? What’s that?)",
    "title": "But guess what?",
    "subtitle": "It doesn’t have to be this way.",
    "paragraph": "Motherhood is not about:\nliving in a perpetual state of exhaustion\ntrudging through life, with a smile plastered to your face\nand it’s also not about\nbeing martyr to your kids and husband"
  },
  "slider_section_2": {
    "id": 2,
    "slider_title": "Absa-bloomin-lutely!",
    "slider_subtitle": "But f’real. We all know motherhood is tough. Is this really possible?",
    "slider_CTA": "TELL ME HOW",
    "slider_items": [
      {
        "id": 2,
        "slider_text": "Wake up with a smile on your face instead of moaning through the morning"
      }
    ],
    "slider_image": null
  },
  "hudi_section": {
    "id": 1,
    "Title": "Hi, I’m Hudi",
    "subtitle": "Wife, mother, and huuuge fan of anything adventurous. (Go Karting? Rock climbing? I’m all for it!)",
    "description": "Wife, mother, and huuuge fan of anything adventurous. (Go Karting? Rock climbing? I’m all for it!) I’ve been there. Yep. I’ve been in the mud of not-pretty overwhelm, hyper-caffeinated exhaustion, and the losing-it-with-everyone-every-single-day situation. Until I realized that I was wasting my life. Wishing for the day when my kids would fly the coop was NOT how I wanted to live. Now? I help overwhelmed Moms enjoy life again, and stop counting the minutes until bedtime. Because why trudge through life, when you don’t have to? I’ve helped hundreds of Moms: -Find their inner selves -Live with joy, calm, and vibrancy -Enjoy spending time with their kids -Fix strained relationships",
    "label": "And I want to help you too.",
    "CTA": "I WANT THAT"
  },
  "directions_section": {
    "id": 1,
    "title": "Choose your direction",
    "direction": [
      {
        "id": 1,
        "title": "Mastery Program",
        "subtitle": null,
        "description": "Clear structured content, Transformational workshops, Supportive community, Hand-held coaching",
        "CTA": "LEARN MORE"
      },
      {
        "id": 2,
        "title": "One-on-one coaching",
        "subtitle": "It’s just you and your coach in the zoom room.",
        "description": "Meet with one of our trained coaches and create a personalized plan. Use these coaching sessions to help you work through whatever you may be dealing with.",
        "CTA": "LEARN MORE"
      },
      {
        "id": 3,
        "title": "Video program",
        "subtitle": "It’s just you and your coach in the zoom room.",
        "description": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis dis partur.",
        "CTA": "LEARN MORE"
      }
    ]
  },
  "image_section": {
    "id": 1,
    "title": "“The stress has disappeared”",
    "paragraph": "I have renewed energy each day. I wake up smiling, excited to learn and accomplish each day. I also have an exercise routine, somewhat healthier eating habits, and the main thing? The stress has disappeared. I'm overall, the person I was hoping I would become!\n",
    "author": "Avigail Boim",
    "image": null
  },
  "testimonails_section": {
    "id": 1,
    "subtitle": "It’s not just about tackling one aspect",
    "paragraph": "Here’s where you get to the core root of what’s not working for you.\nThe program is not focused on tips and ideas. It’s focused on helping you find your own happiness. We make it possible for\nyou to heal deeply on the inside and naturally feel filled with energy",
    "dynamic_title": [
      {
        "id": 1,
        "title": "Why this route?"
      },
      {
        "id": 2,
        "title": "Why not therapy?"
      },
      {
        "id": 3,
        "title": "Or maybe life coaching?"
      },
      {
        "id": 4,
        "title": "Or parenting classes?"
      }
    ],
    "testimonial_slider": [
      {
        "id": 1,
        "testimonial": "My life has transformed in a million little ways. I sometimes wanna pinch myself to see if this is really my life.”",
        "testimonial_author": "Chavi S"
      },
      {
        "id": 2,
        "testimonial": "My life has transformed in a million little ways. I sometimes wanna pinch myself to see if this is really my life.”",
        "testimonial_author": "Chavi S"
      },
      {
        "id": 3,
        "testimonial": "My life has transformed in a million little ways. I sometimes wanna pinch myself to see if this is really my life.”",
        "testimonial_author": "Chavi S"
      }
    ]
  },
  "summary_section": {
    "id": 1,
    "title": "Here’s the thing:",
    "paragraph": "You can keep:\n-Sticking on a smile, when inside you’re feeling wholly and completely burnt out.\n-Living on caffeine, just to keep moving, keep moving, keep moving.\n-Catering to everyone else’s needs BUT your own because “I’m a Mom”.\n-Getting annoyed for no apparent reason and then hating yourself for it.",
    "bold_paragraph": "OR\nLike 100’s of moms before you,\nquit “simply existing”,\nfix those important relationships,\nand start living with joy.",
    "CTA": "SHOW ME HOW"
  }
};
