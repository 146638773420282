import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Subscribe from './components/Subscribe';
import Videos from './components/Videos';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ScrollToTop from './HoCs/ScrollToTop';
import './styles/App.css';
import Confirmation from "./components/Confirmation";
import Account from "./components/Account";
import SuccessfullPayment from "./components/SuccessfullPayment";
import FailedPayment from "./components/FailedPayment";
import PasswordReset from "./components/PasswordReset";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Header />
        <div className="content-wrapper"> 
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          {/* <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/confirmation">
            <Confirmation />
          </Route>
          <Route exact path="/account">
            <Account />
          </Route> */}
          <Route path="/about">
            <About />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          {/* <Route path="/subscribe">
            <Subscribe />
          </Route>
          <Route path="/videos">
            <Videos />
          </Route>
          <Route path="/successfull-payment">
            <SuccessfullPayment />
          </Route>
          <Route path="/failed-payment">
            <FailedPayment />
          </Route>
          <Route path="/reset-password">
            <PasswordReset />
          </Route> */}
        </Switch>
        </div>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

export default App;
