import {useState} from 'react';
import http from '../../../http';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import './style.scss'

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const ForgotPassword = () => {
  const [sent, setSent] = useState(false);
  
  return (<div className="login-container">
    <div className="login-form">
      <h1>Forgot password?</h1>
      <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        terms: false,
      }}
      validationSchema= {SignUpSchema}
      onSubmit={async (values, formik) => {
        const payload = await http.post('auth/forgot-password', {
            email: values.email,
            url: 'auth/forgot-password',
          })
          .then(response => {
            // Handle success.
            console.log('Your user received an email');
          })
          .catch(error => {
            // Handle error.
            console.log('An error occurred:', error.response);
          });
          setSent(true)
          formik.resetForm();
      }}
    >
      {({ errors, touched, dirty, isValid }) => (
      <Form >
      <Field placeholder="Email" name="email" type="text"/>
      {errors.email && touched.email && <div class="input-error">{errors.email}</div>}
      <button  disabled={!(isValid && dirty)} type="submit">SEND</button>
      {sent && (<div className="submit-success">Sent! Check your email for password recovery.</div>)}
      </Form>
      )}
      </Formik>

    </div>
  </div>)
}

export default ForgotPassword;
