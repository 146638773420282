import { Field, Form, Formik } from 'formik';
import http from '../../../http';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";

import './style.scss'

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
    lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
    email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .required('This field is required')
    .min(4, 'Too Short!'),
  confirmPassword: Yup.string().when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Both passwords must match'
    ),
  }),
  email: Yup.string().email('Invalid email').required('This field is required'),
  terms: Yup.boolean().isTrue('Terms must be accepted'),
});

const Register = () => {
  const history = useHistory();

  return (<div className="login-container">
    <div className="login-form">
      <h1>Register</h1>
      <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        terms: false,
      }}
      validationSchema= {SignUpSchema}
      onSubmit={async (values) => {
        const data = values;
        data.username = values.email;
        const payload  = await http.post('/auth/local/register', data);
        if(payload){
          history.push("/confirmation?type=register")
        }
      }}
    >
      {({ errors, touched, dirty, isValid }) => (
      <Form >
      <Field placeholder="First Name" name="firstName" type="text"/>
      {errors.firstName && touched.firstName && <div class="input-error">{errors.firstName}</div>}
      <Field placeholder="Last Name" name="lastName" type="text"/>
      {errors.lastName && touched.lastName && <div class="input-error">{errors.lastName}</div>}
      <Field placeholder="Email" name="email" type="text"/>
      {errors.email && touched.email && <div class="input-error">{errors.email}</div>}
      <Field placeholder="Password" name="password" type="password"/>
      {errors.password && touched.password && <div class="input-error">{errors.password}</div>}
      <Field placeholder="Repeat Password" name="confirmPassword" type="password"/>
      {errors.confirmPassword && touched.confirmPassword && <div class="input-error">{errors.confirmPassword}</div>}
      <div className="login-actions-wrapper">
        <label>
          <Field type="checkbox" name="terms"  style={{
              'marginRight' : '5px'
            }}/>
          I agree to the terms of service
          <span class="checkmark"></span>
        </label>
      </div>
      <button  disabled={!(isValid && dirty)} type="submit">REGISTER</button>
      </Form>
      )}
      </Formik>
    </div>
  </div>)
}

export default Register;
